// Libs
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
// Material
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

// Components
import {MHRegularButton} from '../buttons';

// Styles
import styles from './styles.module.scss';

type MHDialogProps = {
    open: boolean;
    children: React.ReactNode;
    onClose?: () => void;
    onSubmit?: () => void;
    title?: string;
    dialogStyle?: {paper?: string; container?: string};
    contentStyle?: {root: string};
    dialogActionsStyles?: object;
    headTitleStyles?: string;
    /** this prop will be deprecated soon*/
    showControls?: boolean;
    submitButtonDisabled?: boolean;
    submitButtonLoading?: boolean;
    isCloseIcon?: boolean;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    disableEscapeKeyDown?: boolean;
    submitButtonText?: string;
    cancelButtonText?: string;
    scroll?: 'body' | 'paper';
};

export default function MHDialog({
    onClose,
    onSubmit,
    open,
    title,
    dialogStyle = {paper: '', container: ''},
    contentStyle = {root: ''},
    dialogActionsStyles = {},
    headTitleStyles = '',
    showControls = false,
    submitButtonDisabled = false,
    submitButtonLoading = false,
    isCloseIcon = false,
    fullWidth = false,
    maxWidth = false,
    disableEscapeKeyDown = false,
    submitButtonText = 'Submit',
    cancelButtonText = 'Cancel',
    children,
    scroll,
}: MHDialogProps) {
    const dialogClasses = {
        ...dialogStyle,
        paper: classnames(styles.dialogPaper, {
            [dialogStyle.paper]: dialogStyle?.paper,
        }),
        container: classnames(styles.dialogContainer, {[dialogStyle.container]: dialogStyle?.container}),
    };
    const contentClasses = {
        ...contentStyle,
        root: classnames(styles.dialogContent, {[contentStyle.root]: contentStyle?.root}),
    };

    const closeHandler = (_event: object, reason?: string) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <Dialog
            scroll={scroll}
            aria-labelledby="simple-dialog-title"
            open={!!open}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            disableEscapeKeyDown={disableEscapeKeyDown}
            classes={dialogClasses}
            onClose={closeHandler}
        >
            <DialogContent classes={contentClasses}>
                {(title || isCloseIcon) && (
                    <div
                        className={classnames(
                            styles.headerTitle,
                            {[styles.headerTitleWithCloseIcon]: isCloseIcon},
                            headTitleStyles
                        )}
                    >
                        {title && <Typography classes={{root: styles.title}}>{title}</Typography>}
                        {isCloseIcon && (
                            <IconButton
                                aria-label="close"
                                className={styles.closeButton}
                                onClick={closeHandler}
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </div>
                )}
                {children}
            </DialogContent>

            {showControls && (
                <DialogActions classes={dialogActionsStyles}>
                    <MHRegularButton
                        text={submitButtonText}
                        btnType="primary-positive"
                        onClick={onSubmit}
                        disabled={submitButtonDisabled}
                        loading={submitButtonLoading}
                        margin="0 16px 0 0"
                    />
                    <MHRegularButton text={cancelButtonText} btnType="secondary-positive" onClick={closeHandler} />
                </DialogActions>
            )}
        </Dialog>
    );
}
